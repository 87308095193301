import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const DemoDayTeamInfo = ({ webUrl, teamName, teamDescription, teamLogo, links }) => {
    const { t } = useTranslation();
    return <>
        <li className="w-full card-b pb-16">
            <a href={webUrl} target="_blank" className="box-item bg-black py-24 px-24 flex-col md:flex-row">
                <div className="w-full md:w-[248px] rounded-40 custom-shadow">
                    <img
                        className="image-sd"

                        src={teamLogo}
                        width="248"
                        height="248"
                        alt={teamName}
                    />
                </div>
                <div className="text-left pt-24 px-24 md:pt-0 md:w-3/4">
                    <p className="text-28 font-medium">
                        <Trans>{teamName}</Trans>
                    </p>
                    <p className="mt-10 text-menu-white leading-normal px-45">
                        <Trans>
                            {teamDescription}
                        </Trans>
                    </p>
                    <ul className="flex items-center -mx-7 mt-16">
                        {links}
                    </ul>
                </div>
            </a>
        </li>
    </>;
}

export default DemoDayTeamInfo;