import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const LinkMedium = ({ linkUrl }) => {
    const { t } = useTranslation();
    return <>
        <li className="px-7">
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener"
                title="GitBook"
                className="group flex items-center justify-center w-32 h-32 rounded-full text-bg-blue bg-blue-bg transition hover:bg-blue-bg-light"
            >
                <svg className="w-16 h-13 fill-current" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.89733 2.59133C1.91733 2.39467 1.842 2.20067 1.69533 2.06867L0.202 0.268667V0H4.84067L8.426 7.86333L11.578 0H16V0.268667L14.7227 1.49333C14.6127 1.57733 14.558 1.71533 14.5807 1.852V10.8507C14.558 10.9867 14.6127 11.1247 14.7227 11.2087L15.97 12.4333V12.702H9.69533V12.4333L10.988 11.1787C11.1147 11.052 11.1147 11.0147 11.1147 10.8207V3.54733L7.522 12.6727H7.03667L2.85333 3.54733V9.66333C2.81867 9.92 2.904 10.1793 3.08467 10.3647L4.76533 12.4033V12.6727H0V12.4033L1.68067 10.3647C1.86067 10.1787 1.94067 9.918 1.89733 9.66333V2.59133Z" fill="white" />
                </svg>
                <span className="hidden">
                    <Trans>Medium</Trans>
                </span>
            </a>
        </li>
    </>;
}

export default LinkMedium;