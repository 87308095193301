import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const DemoDayLanding = () => {
    const { t } = useTranslation();
    return <>
        <section className="w-full relative z-20 -mb-10">
            <div className="absolute w-full h-full top-0 left-0 -z-10">
                <img className="w-full h-full object-cover" srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
                    src="/images/page-bg.webp" alt="page-bg" />
            </div>
            <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
                <div className="layout-grid"><span></span><span></span><span></span><span></span></div>
            </div>

            <div className="container pt-110 lg:pt-185 sc-up-mb relative">
                <div className="text-center animate-from-bottom">
                    <p>July 27 2022, 4 PM CET <span className="mx-8">|</span> 10 AM EST <span className="mx-8">|</span> 9 AM CT</p>
                    <h1 className="heading-3 heading-1-2 md:text-[58px]">
                        <Trans>Milkomeda Accelerator Batch 1</Trans>
                        <br />
                        <Trans>Demo Day</Trans>
                    </h1>
                    <p className="align-center mt-24 mb-24 mr-140 ml-140 text-menu-white font-normal md:text-20 intro-text">
                        <Trans>
                            With the support of Project Catalyst, and in cooperation with dlab, we are glad to invite you to our first Demo Day!
                            This virtual Demo Day will bring Batch 1 startups to showcase what they have been building for the last 6 weeks on Milkomeda C1.
                        </Trans>
                    </p>
                    <div className="flex flex-wrap sm:flew-nowrap justify-center mt-40">
                        <a className="mr-32 h-[22px] cursor-pointer" href="/">
                            <img
                                src="/images/demo/logo-milkomeda.svg"
                                width="155"
                                height="22"
                                alt="Milkomeda"
                            />
                        </a>
                        <a className="mr-32 h-[23px] cursor-pointer" href="https://dlab.vc/" target="_blanck">
                            <img
                                src="/images/demo/logo-dlab.svg"
                                width="54"
                                height="23"
                                alt="dlab"
                            />
                        </a>
                        <a className="h-[24px] cursor-pointer logo-xs" href="https://cardano.ideascale.com/" target="_blanck">
                            <img
                                src="/images/demo/logo-catalyst.svg"
                                width="95"
                                height="24"
                                alt="Catalyst"
                            />
                        </a>
                    </div>
                </div>
                <div className="space-custom"></div>
            </div>

            <div className="w-full -z-10"><img className="w-full" srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
                src="/images/oval_BG.webp" alt="oval-top" /></div>
        </section >

    </>;
}

export default DemoDayLanding;