import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const LinkInsta = ({ linkUrl }) => {
    const { t } = useTranslation();
    return <>
        <li className="px-7">
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener"
                title="Twitter"
                className="flex items-center justify-center w-32 h-32 rounded-full text-bg-blue bg-blue-bg transition hover:bg-blue-bg-light"
            >
                <svg className="w-14 h-14 fill-current" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <g id="icon">
                        <path id="Fill 33" fillRule="evenodd" clipRule="evenodd" d="M10.3488 1.50406C10.3488 1.27384 10.5476 1.08713 10.7931 1.08713H12.2896C12.5351 1.08713 12.734 1.27384 12.734 1.50406V2.94094C12.734 3.17116 12.5351 3.35787 12.2896 3.35787H10.7931C10.5476 3.35787 10.3488 3.17116 10.3488 2.94094V1.50406ZM6.99066 3.30367C7.98997 3.30367 8.89281 3.68846 9.54288 4.30769H13.9997V2.60001H14C14 1.16411 12.76 0 11.231 0H3.66459V3.27802H3.09682V0H2.76902C2.74758 0 2.72649 0.00097415 2.70505 0.00129887V3.27802H2.13728V0.06884C1.99965 0.0990386 1.86618 0.138654 1.73755 0.187037V3.27802H1.16978V0.478308C0.46231 0.949147 0 1.7236 0 2.60001V4.30769H4.4388C5.08852 3.68846 5.99136 3.30367 6.99066 3.30367Z" fill="white" />
                        <path id="Fill 54" fillRule="evenodd" clipRule="evenodd" d="M7.00849 8.98957C5.90944 8.98957 5.01886 8.09866 5.01886 6.99994C5.01886 5.90123 5.90944 5.01031 7.00849 5.01031C8.10754 5.01031 8.99812 5.90123 8.99812 6.99994C8.99812 8.09866 8.10754 8.98957 7.00849 8.98957ZM8.77264 4.97622C8.5753 4.80317 8.35232 4.65845 8.11045 4.54814C7.77176 4.39431 7.39562 4.30762 6.99992 4.30762C6.60423 4.30762 6.22843 4.39431 5.8894 4.54814C5.64753 4.65845 5.42455 4.80317 5.22721 4.97622C4.66419 5.47009 4.30762 6.19402 4.30762 6.99992C4.30762 8.48456 5.51529 9.69223 6.99992 9.69223C8.48456 9.69223 9.69223 8.48456 9.69223 6.99992C9.69223 6.19402 9.33566 5.47009 8.77264 4.97622Z" fill="white" />
                        <path id="Fill 60" fillRule="evenodd" clipRule="evenodd" d="M2.76909 14H11.2313C12.7607 14 14 12.7457 14 11.1986V4.84619H9.91034C10.327 5.43853 10.5732 6.16278 10.5732 6.94511C10.5732 8.94711 8.96945 10.5698 6.99084 10.5698C5.01257 10.5698 3.4088 8.94711 3.4088 6.94511C3.4088 6.16278 3.65465 5.43853 4.07168 4.84619H0V11.1986C0 12.7457 1.24 14 2.76909 14Z" fill="white" />
                    </g>
                </svg>
                <span className="hidden">
                    <Trans>Instagram</Trans>
                </span>
            </a>
        </li>
    </>;
}

export default LinkInsta;