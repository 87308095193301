import * as React from "react";
import { graphql } from "gatsby";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import FooterSection from "../components/FooterSection";
import MilkHeader from "../components/MilkHeader";
import PageMeta from "../components/PageMeta";
import DemoDayLanding from "../components/DemoDayLanding";
import DemoDayTeams from "../components/DemoDayTeams";

const DemoDayPage = () => {
  const { t } = useTranslation();
  const langContext = React.useContext(I18nextContext);
  return (
    <>
      <main>
        <PageMeta
          title={t("Accelerator Program Demo Day • Milkomeda")}
          description={t(
            "Milkomeda brings the most used smart-contract programming language across blockchains. Develop once, deploy everywhere."
          )}
          locale={t("en_US")}
          language={langContext.language}
        />
        <MilkHeader />
        <div>
          <DemoDayLanding />
          <DemoDayTeams />
        </div>
      </main>
      <FooterSection
        footerImage={
          "/images/icons/hackathon/star-swirl-bottom2x.webp 2x, /images/icons/hackathon/star-swirl-bottom2x.webp 1x"
        }
      />
    </>
  );
};

export default DemoDayPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;