import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const LinkDoc = ({ linkUrl }) => {
    const { t } = useTranslation();
    return <>
        <li className="px-7">
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener"
                title="GitBook"
                className="group flex items-center justify-center w-32 h-32 rounded-full text-bg-blue bg-blue-bg transition hover:bg-blue-bg-light"
            >
                <svg className="w-12 h-16 fill-current" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 16C11.5515 16 12 15.5515 12 15V1C12 0.4485 11.5515 0 11 0H4.5C4.0905 0 3.583 0.21025 3.293 0.5L0.5 3.29275C0.21025 3.5825 0 4.09 0 4.5V15C0 15.5515 0.4485 16 1 16H11ZM11 1V15H1V4.5H3.5C4.0515 4.5 4.5 4.0515 4.5 3.5V1H11ZM3.5 1.707V3.5H1.707L3.5 1.707Z" fill="white" />
                    <path d="M6 4H9C9.13261 4 9.25979 3.94732 9.35355 3.85355C9.44732 3.75979 9.5 3.63261 9.5 3.5C9.5 3.36739 9.44732 3.24021 9.35355 3.14645C9.25979 3.05268 9.13261 3 9 3H6C5.86739 3 5.74021 3.05268 5.64645 3.14645C5.55268 3.24021 5.5 3.36739 5.5 3.5C5.5 3.63261 5.55268 3.75979 5.64645 3.85355C5.74021 3.94732 5.86739 4 6 4ZM9 5.5H3C2.86739 5.5 2.74021 5.55268 2.64645 5.64645C2.55268 5.74021 2.5 5.86739 2.5 6C2.5 6.13261 2.55268 6.25979 2.64645 6.35355C2.74021 6.44732 2.86739 6.5 3 6.5H9C9.13261 6.5 9.25979 6.44732 9.35355 6.35355C9.44732 6.25979 9.5 6.13261 9.5 6C9.5 5.86739 9.44732 5.74021 9.35355 5.64645C9.25979 5.55268 9.13261 5.5 9 5.5ZM9 8H3C2.86739 8 2.74021 8.05268 2.64645 8.14645C2.55268 8.24021 2.5 8.36739 2.5 8.5C2.5 8.63261 2.55268 8.75979 2.64645 8.85355C2.74021 8.94732 2.86739 9 3 9H9C9.13261 9 9.25979 8.94732 9.35355 8.85355C9.44732 8.75979 9.5 8.63261 9.5 8.5C9.5 8.36739 9.44732 8.24021 9.35355 8.14645C9.25979 8.05268 9.13261 8 9 8ZM9 10.5H3C2.86739 10.5 2.74021 10.5527 2.64645 10.6464C2.55268 10.7402 2.5 10.8674 2.5 11C2.5 11.1326 2.55268 11.2598 2.64645 11.3536C2.74021 11.4473 2.86739 11.5 3 11.5H9C9.13261 11.5 9.25979 11.4473 9.35355 11.3536C9.44732 11.2598 9.5 11.1326 9.5 11C9.5 10.8674 9.44732 10.7402 9.35355 10.6464C9.25979 10.5527 9.13261 10.5 9 10.5ZM9 13H3C2.86739 13 2.74021 13.0527 2.64645 13.1464C2.55268 13.2402 2.5 13.3674 2.5 13.5C2.5 13.6326 2.55268 13.7598 2.64645 13.8536C2.74021 13.9473 2.86739 14 3 14H9C9.13261 14 9.25979 13.9473 9.35355 13.8536C9.44732 13.7598 9.5 13.6326 9.5 13.5C9.5 13.3674 9.44732 13.2402 9.35355 13.1464C9.25979 13.0527 9.13261 13 9 13Z" fill="white" />
                </svg>

                <span className="hidden">
                    <Trans>Docs</Trans>
                </span>
            </a>
        </li>
    </>;
}

export default LinkDoc;