import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import DemoDayTeamInfo from "./DemoDayTeamInfo";
import LinkDiscord from "./LinkDiscord";
import LinkTwitter from "./LinkTwitter";
import LinkInsta from "./LinkInsta";
import LinkMedium from "./LinkMedium";
import LinkYouTube from "./LinkYouTube";
import LinkTelegram from "./LinkTelegram";
import LinkWebsite from "./LinkWebsite";
import LinkDoc from "./LinkDoc";

const DemoDayTeams = () => {
    const { t } = useTranslation();
    return <>
        <section className="w-full bg-blue-bg-dark relative">
            <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
                <div className="layout-grid">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="container relative">

                <div className="py-40 px-20 lg:px-128 lg:py-0">
                    <div className="embed-container">
                        <iframe src="https://www.youtube.com/embed/TCMTOWBxjLk" title="YouTube video player" frameBorder="0" allow="encrypted-media; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>

                <div className="w-full pt-80 sc-up-mb relative">
                    <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
                        <DemoDayTeamInfo
                            webUrl="https://www.xdao.app/"
                            teamName="XDAO"
                            teamDescription="XDAO is a DAO-building platform that empowers users to create and manage their own decentralized autonomous organizations without the necessity for coding skills. As a fully customizable DAO builder, xDAO allows companies and projects to invest in decentralized protocols as a joint entity. In short, xDAO provides the tools for groups to control a mutual treasury, build communities and invest in DeFi."
                            teamLogo="/images/demo/demo-team-1.png"
                            links={
                                <>
                                    <LinkTwitter linkUrl="https://twitter.com/xdaoapp/" />
                                    <LinkInsta linkUrl="https://www.instagram.com/xdaoapp/" />
                                    <LinkMedium linkUrl="https://xdaoapp.medium.com/" />
                                    <LinkYouTube linkUrl="https://www.youtube.com/c/XDAOOfficialChannel" />
                                </>
                            }
                        />
                        <DemoDayTeamInfo
                            webUrl="https://singlefinance.io/"
                            teamName="Single Finance"
                            teamDescription="Single Finance is a platform for leveraged yield farming, with integrated risk management tools, for EVM-compatible blockchains. Single Finance is geared toward experienced traders and retail and institutional investors looking to access leveraged yield farming."
                            teamLogo="/images/demo/demo-team-2.png"
                            links={
                                <>
                                    <LinkTwitter linkUrl="https://twitter.com/single_finance" />
                                    <LinkTelegram linkUrl="https://t.me/singlefinanceofficial" />
                                    <LinkDiscord linkUrl="https://discord.com/invite/97W57CjJme" />
                                    <LinkMedium linkUrl="https://medium.com/singlefinance" />
                                    <LinkYouTube linkUrl="https://www.youtube.com/c/SingleFinance" />
                                </>
                            }
                        />
                        <DemoDayTeamInfo
                            webUrl="https://www.lemuria.finance/#/milkomeda"
                            teamName="Lemuria"
                            teamDescription="Lemuria is the first native yield-optimizing protocol for Milkomeda. The Lemuria protocol makes it easy for asset holders on Milkomeda to maximize their yield as they provide liquidity to DEXs, lending platforms, and other yield farming opportunities on the sidechain by automatically compounding the interest they receive."
                            teamLogo="/images/demo/demo-team-3.png"
                            links={
                                <>
                                    <LinkTwitter linkUrl="https://twitter.com/lemuriafinance" />
                                    <LinkTelegram linkUrl="https://t.me/LemuriaFinanceChat" />
                                    <LinkDiscord linkUrl="https://discord.gg/P5kbWGfch8" />
                                </>
                            }
                        />
                        {/* <DemoDayTeamInfo
                            teamName="CreamTank"
                            teamDescription="CreamTank is the first lending and borrowing protocol for over-collateralized loans in the Milkomeda ecosystem. Users provide liquidity to pools of select assets, and in return, they receive a pro-rata distribution of interest rates for loans issued through that pool. A DAO of token holders will govern the CreamTank protocol."
                            teamLogo="/images/demo/demo-team-4.png"
                            links={
                                <>
                                    <LinkTwitter linkUrl="https://twitter.com/TankCream" />
                                    <LinkTelegram linkUrl="https://t.me/creamtank" />
                                    <LinkDiscord linkUrl="https://discord.com/invite/rYGCP3MPEU" />
                                </>
                            }
                        /> */}
                        <DemoDayTeamInfo
                            webUrl="https://www.djed.one/"
                            teamName="Djed Alliance"
                            teamDescription="Djed is an algorithmic crypto-backed stablecoin protocol managed by smart contracts, which function like a fully autonomous central bank, buying and selling stablecoins and reserve coins to maintain the stablecoin’s peg. 

                            The Djed Alliance is an open and free association of entities, individuals, and organizations with the common goal of researching, developing, deploying, and using Djed, the first stablecoin protocol where stability claims have been mathematically proven and formally verified.
                            "
                            teamLogo="/images/demo/demo-team-5.png"
                            links={
                                <>
                                    <LinkWebsite linkUrl="https://www.djed.one/" />
                                    <LinkDoc linkUrl="https://eprint.iacr.org/2021/1069.pdf" />
                                </>
                            }
                        />
                    </ul>

                </div>

            </div>
        </section>
    </>;
}

export default DemoDayTeams;