import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const LinkTwitter = ({ linkUrl }) => {
    const { t } = useTranslation();
    return <>
        <li className="px-7">
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener"
                title="Twitter"
                className="flex items-center justify-center w-32 h-32 rounded-full text-bg-blue bg-blue-bg transition hover:bg-blue-bg-light"
            >
                <svg
                    className="w-16 h-16 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.999756 12.6387C2.34868 13.4982 3.95096 14 5.67225 14C11.3315 14 14.5288 9.2492 14.336 4.98821C14.9315 4.56153 15.4484 4.02818 15.8569 3.42009C15.31 3.661 14.7226 3.82391 14.1064 3.89684C14.7354 3.52277 15.2188 2.92883 15.4462 2.22096C14.8573 2.56818 14.205 2.82034 13.511 2.9564C12.955 2.36754 12.1628 2 11.2859 2C9.31854 2 7.87251 3.82464 8.3168 5.71966C5.7836 5.5934 3.53734 4.38737 2.03436 2.55403C1.23559 3.91607 1.62001 5.69753 2.97769 6.59914C2.47791 6.58354 2.00734 6.44676 1.59664 6.21999C1.56378 7.62375 2.57539 8.93608 4.0415 9.22888C3.61218 9.34498 3.14234 9.37111 2.66519 9.2804C3.05253 10.4846 4.1784 11.3597 5.51235 11.3844C4.23133 12.3829 2.617 12.8284 0.999756 12.6387Z"
                    />
                </svg>
                <span className="hidden">
                    <Trans>Twitter</Trans>
                </span>
            </a>
        </li>
    </>;
}

export default LinkTwitter;