import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const LinkTelegram = ({ linkUrl }) => {
    const { t } = useTranslation();
    return <>
        <li className="px-7">
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener"
                title="Telegram"
                className="group flex items-center justify-center w-32 h-32 rounded-full text-bg-blue bg-blue-bg transition hover:bg-blue-bg-light"
            >
                <svg
                    className="w-16 h-16 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.0573 13.8687C12.1531 13.9364 12.2642 13.9794 12.3806 13.9941C12.4971 14.0088 12.6153 13.9946 12.725 13.9529C12.8344 13.9117 12.9318 13.844 13.0085 13.7558C13.0852 13.6675 13.1387 13.5616 13.1641 13.4476C13.6815 10.9932 14.9508 4.77294 15.4381 2.57119C15.4553 2.48801 15.4499 2.40175 15.4224 2.32137C15.3949 2.24099 15.3464 2.16944 15.282 2.11417C15.2175 2.05889 15.1393 2.02191 15.0557 2.00707C14.9721 1.99223 14.886 2.00007 14.8064 2.02978C12.2678 2.93214 4.50155 5.84974 1.32527 7.0228C1.22771 7.05914 1.14395 7.12507 1.0857 7.21136C1.02745 7.29765 0.997638 7.39999 1.00042 7.50406C0.998725 7.61207 1.03253 7.71766 1.09665 7.8046C1.16077 7.89154 1.25165 7.95503 1.35535 7.98531C2.78107 8.41243 4.65194 9.00798 4.65194 9.00798C4.65194 9.00798 5.52422 11.5947 5.98141 12.9603C6.00751 13.044 6.05425 13.1199 6.11732 13.1808C6.18039 13.2418 6.25777 13.2859 6.34235 13.3092C6.42771 13.3297 6.51679 13.3289 6.60176 13.3069C6.68673 13.2848 6.76498 13.2423 6.82962 13.1829L8.69449 11.4263C8.69449 11.4263 10.8421 13.0024 12.0573 13.8687ZM5.44 8.64704L6.45064 11.9797L6.67923 9.86822L12.7972 4.3759C12.8259 4.34478 12.8419 4.30397 12.8419 4.2616C12.8419 4.21924 12.8259 4.17842 12.7972 4.1473C12.772 4.11364 12.7347 4.09107 12.6932 4.08434C12.6517 4.07761 12.6092 4.08724 12.5746 4.11121L5.44 8.64704Z"
                    />
                </svg>
                <span className="hidden">
                    <Trans>Telegram</Trans>
                </span>
            </a>
        </li>
    </>;
}

export default LinkTelegram;