import * as React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const LinkYouTube = ({ linkUrl }) => {
    const { t } = useTranslation();
    return <>
        <li className="px-7">
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener"
                title="Website"
                className="group flex items-center justify-center w-32 h-32 rounded-full text-bg-blue bg-blue-bg transition hover:bg-blue-bg-light"
            >
                <svg className="w-16 h-12 fill-current" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.9963 3.96236C16.0323 2.92204 15.8047 1.8895 15.3349 0.960618C15.0161 0.579476 14.5737 0.322264 14.0848 0.233804C12.0625 0.0503017 10.0317 -0.0249098 8.00136 0.00849158C5.97838 -0.0264258 3.95498 0.0463587 1.93973 0.226536C1.54131 0.299011 1.17259 0.485894 0.878584 0.764378C0.224452 1.36763 0.15177 2.39971 0.0790889 3.27189C-0.026363 4.84003 -0.026363 6.41349 0.0790889 7.98164C0.100116 8.47254 0.173207 8.95981 0.297133 9.43527C0.384769 9.80236 0.562073 10.142 0.813171 10.4237C1.10918 10.717 1.48648 10.9145 1.89612 10.9907C3.46307 11.1841 5.04193 11.2642 6.62041 11.2305C9.16426 11.2668 11.3956 11.2305 14.0339 11.027C14.4536 10.9555 14.8415 10.7577 15.1459 10.4601C15.3494 10.2565 15.5014 10.0073 15.5893 9.73326C15.8492 8.93567 15.9769 8.10092 15.9672 7.26209C15.9963 6.85508 15.9963 4.39845 15.9963 3.96236ZM6.35876 7.69818V3.1992L10.6615 5.4596C9.45499 6.12827 7.86327 6.88415 6.35876 7.69818Z" fill="white" />
                </svg>

                <span className="hidden">
                    <Trans>You Tube</Trans>
                </span>
            </a>
        </li>
    </>;
}

export default LinkYouTube;